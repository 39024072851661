import React, { Component } from 'react';

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Container from 'react-bootstrap/Container';
import Jumbotron from 'react-bootstrap/Jumbotron';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import InfoBlock from "./Content/InfoBlock";
import Main from './Pages/Main';
import Landing from './Pages/Landing';
import Menu from './Pages/Menu';
import UpButton from './Pages/UpButton';

class App extends Component {
  render() {
    return (
      <Container>
        <Jumbotron>
          <Row>
            <Col className="left-block" md={4} sm={12} lg={4}>
              <InfoBlock />
            </Col>
            <Col>
              <Router>
                <Menu />
                <Switch>
                  <Route exact path={`${process.env.PUBLIC_URL}/`} component={Landing} />
                  <Route exact path={`${process.env.PUBLIC_URL}/cv`} component={Main} />
                  <Route component={Main} />
                </Switch>
              </Router>
            </Col>
          </Row>
        </Jumbotron>
        <UpButton />
        <div className="footer">&copy; 2019-2020 Igor Kim</div>
      </Container>
    );
  }
}

export default App;
