import React from 'react';

import { Link } from "react-router-dom";

import "./Menu.css";

function Menu() {
    return <div className="menu">
        <Link to="/">Home</Link><span className="delimiter">/</span>&ensp;
        <Link to="/cv">CV</Link><span className="delimiter">/</span>&ensp;
        <a href="/cv#skills">Skills</a><span className="delimiter">/</span>&ensp;
        <a href="/cv#education">Education</a><span className="delimiter">/</span>&ensp;
        <a href="/cv#references">References</a>
    </div>;
}

export default Menu;