import React, { useState } from 'react'

import Image from 'react-bootstrap/Image';
import Spinner from 'react-bootstrap/Spinner';

import './ImageContainer.css';

export default function ImageContainer({ alt, src }) {
    const [isLoaded, setIsLoaded] = useState(false);

    return (
        <div className="img">
            {!isLoaded && <Spinner animation="border" role="status" />}
            <Image
                onLoad={() => setIsLoaded(true)}
                hidden={!isLoaded}
                className="screenshot"
                alt={alt}
                src={src} />
        </div>
    )
}
