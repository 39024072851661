import React, { Component } from 'react';
import { Link } from "react-router-dom";

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Badge from 'react-bootstrap/Badge';
import ImageContainer from './ImageContainer';

const techs = {
    "reactjs": { title: "ReactJS" },
    "redux": { title: "Redux" },
    "sass": { title: "SASS" },
    "bootstrap": { title: "Bootstrap" },
    "nivo": { title: "Nivo" },
    "leaflet": { title: "Leaflet" },
    "jest": { title: "Jest" },
    "go": { title: "Go" },
    "openapi": { title: "OpenAPI" },
    "graphql": { title: "GraphQL" },
    "rest": { title: "REST" },
    "mongodb": { title: "MongoDB" }
}

const tools = {
    "vscode": { title: "Visual Studio Code" },
    "insomnia": { title: "Insomnia" },
    "robo3t": { title: "Robo 3T" },
    "scaleway": { title: "Scaleway" },
    "gh-actions": { title: "Github Actions" },
    "vercel": { title: "Vercel" },
    "jira": { title: "JIRA" }
}

class Landing extends Component {
    renderTechs() {
        let result = [];
        for (let t in techs) {
            result.push(<Badge key={`tech-${t}`} variant="primary">{techs[t].title}</Badge>);
        }
        return result;
    }

    renderTools() {
        let result = [];
        for (let t in tools) {
            result.push(<Badge key={`tool-${t}`} variant="primary">{tools[t].title}</Badge>);
        }
        return result;
    }


    render() {
        this.renderTechs();

        return (
            <>
                <h2># Igor Kim</h2>

                <Row className="block">
                    <Col>
                        <p>I am a full-stack developer (React/Go/Python) and a mobile communications researcher.</p>
                        <p>Check out my <a href="https://github.com/igorskh">GitHub</a> for more projects
                        or <Link to="cv">CV</Link> for career and education details.</p>

                        <p>Currently working on a project using React/Redux and GraphQL/Go backend.</p>
                    </Col>
                </Row>

                <h2># Technologies/Tools</h2>

                <Row className="block">
                    <Col>
                        {this.renderTechs()}
                    </Col>
                    <Col>
                        {this.renderTools()}
                    </Col>
                </Row>

                <h2># My Projects</h2>
                <h3>## Interactive Spectrum Allocation Diagram</h3>
                <p>
                    <a rel="noopener noreferrer" target="_blank" href="https://spectrum-allocation-diagram.now.sh">Web application</a> (<strong>ReactJS</strong>) for interactive representation of mobile spectrum allocation in Germany.
                </p>
                <p>Application features <a rel="noopener noreferrer" target="_blank" href="https://spectrum-allocation-diagram.now.sh/map">snapshots of live measurements</a> implementing lazy-load.</p>
                <ImageContainer alt="spectrum allocation diagram" src="/assets/spectrum-allocation-diagram.png" />

                <h3>## Data Analytics Framework</h3>
                <p>
                    Responsive web application (<strong>ReactJS</strong>)
                    with a corresponding <strong>OpenAPI</strong> backend (<strong>Go</strong>) and <strong>Python</strong> server for stastical analysis, files upload and parsing.
                </p>
                <ImageContainer alt="analytics GUI" src="/assets/analytics.png" />

                <h3>## Web-based RF Simulator and Microservices</h3>
                <p>
                    <strong>ReactJS</strong> application using <strong>Redux</strong> and a corresponding microservices gateway (<strong>Go</strong>, <strong>GraphQL</strong>) backend. <strong>Go</strong>, <strong>Python</strong> and <strong>Matlab</strong> microservices.
                    Web application - microservices connection follows publish-subscribe pattern.
                </p>
                <ImageContainer alt="scenario creator" src="/assets/scenario-creator.png" />

                <h3>## Android Measurement Application</h3>
                <p>
                    Android native application (<strong>Kotlin</strong>) for live measurement of LTE/WCDMA/GSM metrics
                    and round trip time measurement for network performance evalution using Android API.
                    Compatible with <strong>Data Analytics Framework</strong>.
                </p>
                <ImageContainer alt="Harvester app" src="/assets/harvester.png" />

                <h3>## Demonstrator for 5G Summit Dresden 2019</h3>
                <p>
                    Web application (<strong>ReactJS</strong>) with integrated <strong>WebRTC</strong> streaming and <strong>TensorFlowJS</strong> in-browser objects detection with a <strong>Flask</strong> backend.
                    Graphical interfaces enables live reporting from open source software defined radio LTE network with interactive charts, as well as control features to manipulate LTE base station parameters.

                </p>
                <p>
                    Demonstrator development includes extension of open source LTE impementation (<strong>C++</strong>) with REST API.
                </p>
                <ImageContainer alt="5G summit demo" src="/assets/5g_summit_demo.png" />
                <center>
                    <iframe
                        title="axians 5g summit demo video"
                        width="560"
                        height="315"
                        src="https://www.youtube-nocookie.com/embed/79qnZN4NNzw"
                        frameBorder="0"
                    />
                </center>
            </>
        );
    }
}

export default Landing;
