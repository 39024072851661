import React, { Component } from 'react';

import MainBlock from "../Content/MainBlock";

class Main extends Component {
    render() {
        return <MainBlock />;
    }
}

export default Main;
