import React from 'react'

import './UpButton.css';

export default function UpButton() {
    return <div
        onClick={() => window.scrollTo(0, 0)}
        id="up-button">
        Up
    </div>;
}
