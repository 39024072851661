import React, { Component } from 'react';

class InfoBlock extends Component {

    render() {
        return <div id="info-block">
            <a href="/">
                <img className="photo" width="100%" src={"/assets/photo.jpg"} alt="Igor Kim" />
            </a>
            <a href="/">
                <h1>Igor&nbsp;Kim</h1>
            </a>
            <p>Full-stack&nbsp;dev</p>
            <p>Leipzig, Germany</p>
            <p className="social">
                Github: <a target="_blank" rel="noopener noreferrer" href="https://github.com/igorskh">igorskh</a><br />
                PGP: <a href="/assets/igorkim.asc">Public key</a><br />
                E-mail: igor.skh&nbsp;(at)&nbsp;gmail.com<br />
            </p>
        </div>
    }
}

export default InfoBlock;
